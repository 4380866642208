import React from "react";
import Image from "../../../../general-components/image/image";
import classNames from "classnames";

const ParagraphLandingpageIntro = (props) => {
  const sectionClassNames = classNames({
    "paragraph landingpage-intro": true,
    "startseite": props.position === "startseite",
  });
  return (
    <div className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16">
            <article className="media-image">
              {props.content.fieldTeaserbild &&
                <Image
                  data={props.content.fieldTeaserbild.entity.fieldMediaImage}
                />
              }
              <div className="text-wrap">
                {props.content.entityLabel &&
                  <h2 className="headline">{props.content.entityLabel}</h2>
                }
                {props.content.fieldTeasertext &&
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: props.content.fieldTeasertext.processed,
                    }}
                  />
                }
              </div>

            </article>
          </div>
        </div>
      </div>
    </div>

  )
}
export default ParagraphLandingpageIntro