import React from "react";
import classNames from "classnames";

function ParagraphIntroFrontpage() {
  const sectionClassNames = classNames({
    "paragraph paragraph-intro": true,
  });
  return(
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16">
            <h1 className="headline">
              GEDISA 2022
            </h1>
            <p className="subline">
              Die Gesellschaft für digitale Services der Apotheken nach einem Jahr.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ParagraphIntroFrontpage