import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  ArrowDownRight32,
  LogoFacebook32,
  LogoYoutube32,
  LogoFlickr32,
  LogoInstagram32,
  LogoLinkedin32,
  LogoPinterest32,
  LogoTwitter32,
  LogoXing32,
  LogoTumblr32,
} from "@carbon/icons-react";

import LoadingIndicator from "../../../general-components/loading-indicator";
import LogoButton from "./../../../general-components/logo-button";
import { self } from "../../../config";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo Add title attribute to links.
 */
class PageFooter extends Component {
  render() {
    if (this.props.footerConfig && this.props.menu) {
      return (
        <div className="container">
          {this.props.footerConfig && this.props.menu && (
            <>
              <div className="row">
                <div className="col-16">
                  <div className="copyright">
                    {this.props.footerConfig.fieldCopyright
                      .split(/[\r\n]+/)
                      .map((line, i) => (
                        <p key={i}>{line}</p>
                      ))}
                    {this.props.footerConfig.fieldEmail && (
                      <p>
                        <a
                          href={`mailto:${this.props.footerConfig.fieldEmail}`}
                        >
                          {this.props.footerConfig.fieldEmail}
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <nav className="row footer-menu">
                <div className="col-5 rights">
                  <ul>
                    {this.props.menu.links.map((item, index) => (
                      <li key={index}>
                        <Link to={item.url.path}>{item.label}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-6 icons">
                  <div className="social-media">
                    {this.props.footerConfig.fieldFacebook && (
                      <a
                        className="facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.props.footerConfig.fieldFacebook.url.path}
                      >
                        <LogoFacebook32 />
                      </a>
                    )}
                    {this.props.footerConfig.fieldYoutube && (
                      <a
                        className="youtube"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.props.footerConfig.fieldYoutube.url.path}
                      >
                        <LogoYoutube32 />
                      </a>
                    )}
                    {this.props.footerConfig.fieldFlickr && (
                      <a
                        className="flickr"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.props.footerConfig.fieldFlickr.url.path}
                      >
                        <LogoFlickr32 />
                      </a>
                    )}
                    {this.props.footerConfig.fieldInstagram && (
                      <a
                        className="instagram"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.props.footerConfig.fieldInstagram.url.path}
                      >
                        <LogoInstagram32 />
                      </a>
                    )}
                    {this.props.footerConfig.fieldVimeo && (
                      <a
                        className="vimeo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.props.footerConfig.fieldVimeo.url.path}
                      >
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink={"http://www.w3.org/1999/xlink"}
                          x="0px"
                          y="0px"
                          viewBox="0 0 96 96"
                          style={{ enableBackground: "new 0 0 96 96" }}
                          xmlSpace="preserve"
                        >
                          <g id="XMLID_1_">
                            <path
                              id="XMLID_5_"
                              className="st0"
                              d="M15.7,35.4C22.8,29,29.1,18,36.4,20.3c12.1,3.1,6.9,26.4,13.8,37.2c9.4-5.3,15.6-16.5,13-22.3
                          c-0.6-1.6,0.1-5.7-7.6-2.1c-2,0.9-1.9-2.6-1.6-3.1c7.2-7.9,11.3-11.2,16.2-11c4.1,0.7,11.4,5.3,10.1,16.5
                          C77.6,51.9,53.2,78,45.1,77c-9.3,2.1-14-27.4-21-41.2l-6.4,2.5C15,38.7,15.4,37,15.7,35.4L15.7,35.4z"
                              fill="#333333"
                            />
                          </g>
                        </svg>
                      </a>
                    )}
                    {this.props.footerConfig.fieldLinkedin && (
                      <a
                        className="linkedin"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.props.footerConfig.fieldLinkedin.url.path}
                      >
                        <LogoLinkedin32 />
                      </a>
                    )}
                    {this.props.footerConfig.fieldPinterest && (
                      <a
                        className="pinterest"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.props.footerConfig.fieldPinterest.url.path}
                      >
                        <LogoPinterest32 />
                      </a>
                    )}
                    {this.props.footerConfig.fieldTwitter && (
                      <a
                        className="twitter"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.props.footerConfig.fieldTwitter.url.path}
                      >
                        <LogoTwitter32 />
                      </a>
                    )}
                    {this.props.footerConfig.fieldXing && (
                      <a
                        className="xing"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.props.footerConfig.fieldXing.url.path}
                      >
                        <LogoXing32 />
                      </a>
                    )}
                    {this.props.footerConfig.fieldTumblr && (
                      <a
                        className="Tumblr"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.props.footerConfig.fieldTumblr.url.path}
                      >
                        <LogoTumblr32 />
                      </a>
                    )}
                  </div>
                </div>
                <div className="col-5 logo-wrapper">
                  <img
                    src={`${self}/GEDISA-logo-white.svg`}
                    alt="Gedisa Logo"
                  />
                </div>
              </nav>
            </>
          )}
        </div>
      );
    }

    return <LoadingIndicator />;
  }
}

PageFooter.propTypes = {
  adminApp: PropTypes.bool,
  footerConfig: PropTypes.object,
  menu: PropTypes.object,
};

export default connect(mapStateToProps)(PageFooter);
